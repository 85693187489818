<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    title: {
      type: String,
      default: "Data One"
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines: {},
              ticks: {
                min: 0,
                max: 100,
                stepSize: 10,
                reverse: false,
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                lineWidth: 0,
                zeroLineWidth: 1
              }
            }
          ]
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[0].data[tooltipItem.index].items.map(x =>
                x.done ? `+ ${x.content}` : `-  ${x.content}`
              );
            }
          }
        }
      }
    };
  },
  watch: {
    data() {
      this.renderChartData();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderChartData();
    });
  },
  methods: {
    renderChartData() {
      this.renderChart(
        {
          labels: this.data.map(x => x.label),
          datasets: [
            {
              label: this.title,
              backgroundColor: this.$vuetify.theme.currentTheme["primary"],
              data: this.data.map(x => {
                return { y: x.data, items: x.items };
              }),
              barPercentage: 0.2
            }
          ]
        },
        this.options
      );
    }
  }
};
</script>
